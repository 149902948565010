import React from "react";
import ReusableTable from "../Report/ReusableTable";
import { Box, Button } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import {
  calcularTotalPorcentajeCargado,
  formatDescuentosYRecargo,
} from "./utils";
import { TIPO_COEFICIENTE } from "../ModalDescuentoYRecargo/utils";

export default function TablaDescuentosYRecargos({
  coeficientePrincipal = 0,
  coeficientesCargados,
  tipo_coeficiente,
  onAplicar = () => {},
  showAlert = false,
  disabledAplicar = false,
}) {
  const coeficientesCargadosActivos = coeficientesCargados.filter(
    (coeficiente) =>
      coeficiente.activo && coeficiente.tipo_coeficiente === tipo_coeficiente,
  );

  // Suma de coeficientes cargados de manera consecutiva
  const totalCoeficientes = calcularTotalPorcentajeCargado({
    coeficientesCargadosActivos,
    tipo_coeficiente,
  });

  // if (tipo_coeficiente === TIPO_COEFICIENTE.PORCENTAJE_DESCUENTO) {
  //   console.log("totalPorcentajeCargado", totalPorcentajeCargado);
  // }

  // const totalCoeficientes = coeficientesCargadosActivos.reduce(
  //   (acc, coeficiente) => {
  //     return acc + Number(coeficiente.coeficiente);
  //   },
  //   0,
  // );

  const validarCoeficientes = () => {
    return totalCoeficientes !== Number(coeficientePrincipal);
  };

  const mensajeAlerta = () => {
    if (TIPO_COEFICIENTE.PORCENTAJE_DESCUENTO) {
      return (
        <>
          El acumulado de los{" "}
          <span style={{ fontWeight: "bolder" }}>
            {" "}
            descuentos cargados ({`${totalCoeficientes.toFixed(2)}%`}){" "}
          </span>{" "}
          difiere del
          <span style={{ fontWeight: "bolder" }}>
            {" "}
            decuento configurado (
            {`${Number(coeficientePrincipal).toFixed(2)}%`})
          </span>
        </>
      );
    }
    if (
      TIPO_COEFICIENTE.PORCENTAJE_CONTADO ||
      TIPO_COEFICIENTE.PORCENTAJE_LISTA
    ) {
      return (
        <>
          El acumulado de los{" "}
          <span style={{ fontWeight: "bolder" }}>
            {" "}
            recargos cargados ({`${totalCoeficientes.toFixed(2)}%`}){" "}
          </span>{" "}
          difiere del
          <span style={{ fontWeight: "bolder" }}>
            {" "}
            recargo configurado ({`${Number(coeficientePrincipal).toFixed(2)}%`}
            )
          </span>
        </>
      );
    }
  };

  return (
    <>
      <ReusableTable
        boxerStyle={false}
        columns={["Nombre", "Coeficiente"]}
        items={formatDescuentosYRecargo({
          coeficientes: coeficientesCargadosActivos,
        })}
        noResults={"No hay coeficientes cargados"}
      />
      <Box mt={1} />
      {showAlert && validarCoeficientes() && (
        <Alert
          severity="warning"
          action={
            <Button
              disabled={disabledAplicar}
              color="inherit"
              size="small"
              onClick={() => onAplicar(totalCoeficientes)}
            >
              Aplicar recargo
            </Button>
          }
        >
          {mensajeAlerta()}
        </Alert>
      )}
    </>
  );
}
